// PW TOOLS JS FRONT
window.showMessage = showMessage
window.popup = popup
window.toggleSidebar = toggleSidebar
window.scrollToGo = scrollToGo
window.copy = copy

// INPUT FILE CUSTOM
var inputFileItems = document.querySelectorAll('.input-file')

for (var i = 0; i < inputFileItems.length; i++) {
    var fileInput = inputFileItems[i].children[0],
        button = inputFileItems[i].children[1],
        the_return = inputFileItems[i].children[2]

    button.addEventListener('keydown', function (event) {
        if (event.keyCode == 13 || event.keyCode == 32) {
            this.previousElementSibling.focus()
        }
    })

    button.addEventListener('click', function (event) {
        this.previousElementSibling.focus()
        return false
    })

    fileInput.addEventListener('change', function (event) {
        var this_return = this.nextElementSibling.nextElementSibling
        this_return.innerHTML = this.value.replace('C:\\fakepath\\', ' ')
    })
} // END

// Fonction qui affiche une notification en bas du header pendant 3 secondes
// type : ( string ) class qu'aura l'élément ajouté ( valid, error ou delete )
// message : ( string ) message affiché
function showMessage(type, message) {
    var alert = $('<p>')
    alert.addClass('msg-info flash')
    alert.addClass(type)
    alert.html(message)

    $('body').append(alert)

    setTimeout(function () {
        alert.addClass('open')
    }, 100)

    setTimeout(function () {
        alert.removeClass('open')
    }, 3000)

    setTimeout(function () {
        alert.remove()
    }, 3500)
}

// POPUP
function popup(pop) {
    $('body').addClass('mode-popup')
    pop.addClass('open')

    $(document).on('mouseup',function(e){
        if (!pop.is(e.target) && pop.has(e.target).length === 0)
        {
            pop.removeClass('open')
            $('body').removeClass('mode-popup')
        }
    });
}

// TOGGLE SIDEBAR
function toggleSidebar(sidebar) {
    if ($('.sidebar-toggle').hasClass("is-open")) {
        $('.sidebar-toggle').removeClass("is-open");
    }

    sidebar.toggleClass("is-open");

    $(document).mouseup(function (e) {
        if (!sidebar.is(e.target) && sidebar.has(e.target).length === 0) {
            sidebar.removeClass('is-open')
        }
    });
}

// COPY FUNCTION
function copyLegacy(text) {
    let textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus({preventScroll: true})
    textArea.select()
    
    try {
        document.execCommand('copy')
        showMessage('valid','texte copié !');
        
    } catch (err) {
        console.error('Copy error', err)
        showMessage('error','texte non copié !');
    }

    document.body.removeChild(textArea)
}

function copy(selector) {
    let textToCopy = selector.text()

    if (location.protocol === 'https:') {
        // marche uniquement en https
        navigator.clipboard.writeText(textToCopy).then(function() {
            showMessage('valid','texte copié !');
        }, function() {
            showMessage('error','texte non copié !');
        });

    } else {
        copyLegacy(textToCopy)
    }
}

// SCROLL TO GO
function scrollToGo(selector) {
    if (selector.length) {
        if ($('sidebar-toggle').hasClass("is-open")) {
            $('.sidebar-toggle').removeClass("is-open");
        }

        $("html, body").animate({ scrollTop: $(selector).offset().top }, 1000);
    }
    return false
}

$(document).ready(function () {
    // RETOUR EN HAUT - créer balise #gotop
    $('#gotop').on('click', function () {
        //
        $('html, body').animate({ scrollTop: 0 }, 'slow')
        return false
    })

    // BOUTON TOGGLE MAIN NAV
    $('.toggle-nav').on('click', function () {
        $(this).toggleClass('menu-open')
        $('.main-nav').toggleClass('showing-nav')
        return false
    })

    $('.menu a').click(function () {
        $('.toggle-nav').removeClass('menu-open')
        $('.main-nav').removeClass('showing-nav')
    })

    // BOUTON TOGGLE SEARCH
    $('.toggle-search').on('click', function () {
        $(this).toggleClass('search-mobile-on')
        $('.form-search').toggleClass('search-open')
        return false
    })

    // SCROLL TO
    $('a[href^="#"]').on('click', function() {
        let selector = $(this).attr('href')
        scrollToGo(selector)
    })

    // TOGGLE SIDEBAR
    $('[data-toggle-sidebar]').on('click', function () {
        let sidebar = $('#' + $(this).attr('data-toggle-sidebar'))
        toggleSidebar(sidebar)
    });

    // CLOSE TOGGLE
    $('.js-close-toggle').on('click', function () {
        $(this).parents('.sidebar-toggle').removeClass('is-open')
    })

    // COPY JS
    $('[data-copy]').on('click', function() {
        let copyTarget = $('#' + $(this).attr('data-copy'))
        copy(copyTarget)
    });

    // POPUP
    $('[data-popup]').on('click', function () {
        let pop = $('#' + $(this).attr('data-popup'))
        popup(pop)
    });

    $('.close-popup').on('click', function () {
        $('body').removeClass('mode-popup')
        $(this).parent('.popup').removeClass('open')
    })

    // ONGLETS
    $('[data-onglet]').on('click', function () {
        $('.nav-onglets > a').removeClass('actif')
        $('.onglet-content').removeClass('open')
        $(this).addClass('actif')
        $('#' + $(this).attr('data-onglet')).addClass('open')
    })

    // TOGGLE SECTION
    $('.toggle-item').on('click', '> .toggle-launcher', function () {
        if ($(this).parent().hasClass('open')) {
            $(this).parent().removeClass('open')
        } else {
            $('.toggle-section .toggle-item.open').removeClass('open')
            $(this).parent().addClass('open')
        }

        return false
    })

    // RECUP EMAIL NEWSLETTER POPUP
    $('[data-popup-news="inscrinews"]').on('click', function () {
        if ($('#recup-email-news:valid').length != 0) {
            var email = $('#recup-email-news').val()
            $('#email-newsletter').attr('value', email)
            $('body').addClass('mode-popup')
            $('#' + $(this).attr('data-popup-news')).addClass('open')
        }
    })

    //Select custom :
    $('.select-custom select').each(function () {
        const dropdown = $('<div />').addClass('dropdown selectDropdown')

        $(this).wrap(dropdown)

        const label = $('<span />').text($(this).attr('placeholder')).insertAfter($(this))
        const list = $('<ul class="list-select" />')

        $(this)
            .find('option')
            .each(function () {
                list.append($('<li />').append($('<a />').text($(this).text())))
            })

        list.insertAfter($(this))

        if ($(this).find('option:selected').length) {
            label.text($(this).find('option:selected').text())
            list.find('li:contains(' + $(this).find('option:selected').text() + ')').addClass('active')
            $(this).parent().addClass('filled')
        }
    })

    $(document).on('click touch', '.selectDropdown ul li a', function (e) {
        e.preventDefault()
        const dropdown = $(this).parent().parent().parent()
        const active = $(this).parent().hasClass('active')
        const label = active ? dropdown.find('select').attr('placeholder') : $(this).text()

        dropdown.find('option').prop('selected', false)
        dropdown.find('ul li').removeClass('active')

        dropdown.toggleClass('filled', !active)
        dropdown.children('span').text(label)

        if (!active) {
            dropdown.find('option:contains(' + $(this).text() + ')').prop('selected', true)
            $(this).parent().addClass('active')
        }

        dropdown.removeClass('open')
    })

    $('.dropdown > span').on('click touch', function (e) {
        const self = $(this).parent()
        self.toggleClass('open')
    })

    $(document).on('click touch', function (e) {
        const dropdown = $('.dropdown')
        if (dropdown !== e.target && !dropdown.has(e.target).length) {
            dropdown.removeClass('open')
        }
    })

    $('.link-to-go').click(function (event) {
        event.preventDefault()
        let url = $(this).attr('data-href')
        window.open(url)
    }) 

}) // FIN DOCUMENT READY
